import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ThePupil = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Pupil" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Pupil</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Nov. 20, 2022) </p>
        </div>
        <div className="articleBody">
          <p>It's early morning.</p>
          <p>
            A wrinkly hand gently moves the beads of a{" "}
            <a
              href="https://en.wikipedia.org/wiki/Misbaha"
              target="_blank"
              rel="noopener noreferrer"
            >
              sibha
            </a>
            .
          </p>
          <p>The same hand reaches into a bowl of dates soaking in water.</p>
          <p>
            An old sage sits on a large jute mat beneath a tree, dressed in a
            white thobe. Beside him is a tray with a bowl on it. He's engaged in
            <a
              href="https://en.wikipedia.org/wiki/Dhikr"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              dhikr
            </a>
            . He's surrounded by the sound of goats and chickens. As he
            worships, he grabs a date and puts it in his mouth.
          </p>
          <p>This goes on for a time.</p>
          <p>
            A young boy wearing a shirt and pants enters and sits on the other
            side of the tray.
          </p>
          <p>
            The boy reaches into his pocket and pulls out prayer beads of his
            own. He starts to worship as the sage does.
          </p>
          <p>The sage gives him a nonchalant glance and eats another date.</p>
          <p>
            After a moment, the boy reaches for a date of his own. The sage
            smacks his hand forcefully. The boy winces and cries out:
          </p>
          <p>
            BOY: What did I do?! <br />
            SAGE: (chewing) You can't worship while you eat dates. <br />
            BOY: Isn't that exactly what you're doing?! <br />
            SAGE: No. I've never done that in my life.
          </p>
          <p>The boy looks utterly confused.</p>
          <p>SAGE: Move along now.</p>
          <p>
            The boy throws down his prayer beads in frustration and storms off.
          </p>
          <p>---</p>
          <p>
            It's high noon. The boy and an older boy kick a soccer ball back and
            forth on a sandy pitch.
          </p>
          <p>
            Later, they sit side by side on the ground. The older boy drinks a
            <a
              href="https://en.wikipedia.org/wiki/Vimto"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Vimto
            </a>
            , holding the ball under his arm.
          </p>
          <p>
            BOY: I have a question for you. <br />
            OLDER BOY: Uh huh. <br />
            BOY: Can you worship while eating dates?
          </p>
          <p>The older boy grins knowingly.</p>
          <p>OLDER BOY: Of course not.</p>
          <p>Again, the boy looks utterly confused.</p>
          <p>---</p>
          <p>
            It's dusk. The boy feeds some chickens, and watches them carefully
            as they peck at their meal.
          </p>
          <p>---</p>
          <p>It's morning again. </p>{" "}
          <p>
            The sage sits under the same tree, in the same posture as before.
            He's worshipping and eating from his same bowl of dates.
          </p>
          <p>
            The boy enters and joins him, as he did before. He's wearing a
            different shirt. He takes out his prayer beads and starts to
            worship.
          </p>
          <p>After a while, the boy puts his hand out.</p>
          <p>BOY: Give me a date, please.</p>
          <p>The sage eyes the boy sternly.</p>
          <p>
            SAGE: Can you worship while eating dates? <br /> BOY: No. <br />{" "}
            SAGE: Then what will you do? <br /> BOY: I'll do what you're doing.{" "}
            <br /> SAGE: And what am I doing? <br /> BOY: You're eating dates
            while you worship.
          </p>
          <p>The sage's face lights up with a smile.</p>
          <p>SAGE: God is great.</p>
          <p>
            The sage places many dates in the boys hand, and clasps it closed.
            Their prayer beads overlap.{" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default ThePupil
